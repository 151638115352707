import {graphql, useStaticQuery} from "gatsby";
import * as React from "react";
import {Blok} from "../../pages/beschikbaar";

const Blok1En5 = () => {
    const data = useStaticQuery(graphql`
        query {
            allBouwnummer(filter: {bouwtype: {algemeen: {omschrijving: {in: ["Blok 1", "Blok 5"]}}}}) {
                nodes {
                    ...Bouwnummer
                }
            }
            bouwtype(algemeen: {omschrijving: {eq: "Blok 1"}}) {
                ...BouwtypeBeschikbaar
            }
        }
    `)
    return (
        <Blok bouwnummers={data.allBouwnummer.nodes} omschrijving={'Blok 1 en 5'} impressie={data.bouwtype.hoofdfoto} />
    )
}

export default Blok1En5