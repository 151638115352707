import {graphql, useStaticQuery} from "gatsby";
import * as React from "react";
import {Blok} from "../../pages/beschikbaar";

const Blok4En6 = () => {
    const data = useStaticQuery(graphql`
        query {
            allBouwnummer(filter: {bouwtype: {algemeen: {omschrijving: {in: ["Blok 4", "Blok 6"]}}}}) {
                nodes {
                    ...Bouwnummer
                }
            }
            bouwtype(algemeen: {omschrijving: {eq: "Blok 4"}}) {
                ...BouwtypeBeschikbaar
            }
        }
    `)
    return (
        <Blok bouwnummers={data.allBouwnummer.nodes} omschrijving={'Blok 4 en 6'} impressie={data.bouwtype.hoofdfoto} />
    )
}

export default Blok4En6