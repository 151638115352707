import React from 'react'
import Typography from "@mui/material/Typography";
import Box from '@mui/material/Box';
import {textMaxWidth} from "../theme";

const Prijzen = () => {
    return (
        <Box
            color={'#153547'}
            maxWidth={textMaxWidth}
        >
            <Typography
                variant={'h6'}
                color={'#153547'}
            >
                De koopsommen zijn:
            </Typography>
            <ul>
                <li>
                    Vrij Op Naam (V.O.N.), dat wil zeggen inclusief o.a. grond-, kadaster-, makelaars-, architect- en notariskosten m.b.t. de transportakte en 21% BTW;
                </li>
                <li>
                    Incl. de legeskosten van de omgevingsvergunning;
                </li>
                <li>
                    Incl. de eenmalige aansluitkosten van elektra, water en riolering;
                </li>
                <li>
                    Inclusief de kosten in verband met de aanvraag van Woningborg;
                </li>
                <li>
                    Inclusief de CAR-verzekering;
                </li>
                <li>
                    Afmetingen zijn circa maten;
                </li>
                <li>
                    Het gebruikersoppervlak is conform NEN 2580, exclusief oppervlakte van buitenbergingen en buitenruimtes;
                </li>
                <li>
                    Excl. inbouwkeuken en incl. sanitair en tegelwerk in badkamer en toilet.
                </li>
                <li>
                    Excl. maandelijkse leasekosten zonnepanelen.
                </li>
            </ul>

            Prijzen zijn geldig tot 1 augustus 2022. Deze prijslijst is met de grootste zorgvuldigheid samengesteld, desondanks kunnen aan deze lijst geen rechten worden ontleend.
        </Box>
    )
}

export default Prijzen
