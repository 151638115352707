import * as React from "react"
import {graphql} from "gatsby"
import {GatsbyImage, StaticImage} from "gatsby-plugin-image";
import Container from "@mui/material/Container";
import {Box, Grid} from "@mui/material";
import {Bouwnummer} from "../graphqlTypes";
import Typography from "@mui/material/Typography";
import {headerMargin, sectionPadding} from "../theme";
import Gevelaanzichten from "../components/gevelaanzichten";
import Blok1En5 from "../components/beschikbaar/blok1en5";
import Blok2En3 from "../components/beschikbaar/blok2en3";
import Blok4En6 from "../components/beschikbaar/blok4en6";
import Prijzen from "../components/prijzen";

interface BlokProps {
    bouwnummers: Bouwnummer[]
    omschrijving: string
    impressie?: any
}
export const Blok = ({bouwnummers, omschrijving, impressie}: BlokProps) => {
    return (
       <>
           <Grid
               container
               sx={{
                   paddingY: sectionPadding,
                   flexDirection: {md: 'row-reverse'}
               }}
               spacing={3}
           >
               <Grid
                   item
                   xs={12}
                   md={7}
               >
                 <Typography
                   variant={'h2'}
                   color={'primary.main'}
                 >
                   {omschrijving}
                 </Typography>
                 <Typography>Geen bouwnummers beschikbaar</Typography>
               </Grid>
               <Grid item xs={12} md={5}>
                   {impressie &&
                       <GatsbyImage
                           alt={"artist impression"}
                           image={impressie.localFile.childImageSharp.gatsbyImageData} />
                   }
               </Grid>
           </Grid>
       </>
    )
}


export default () => {
    return (
        <article>
            <Box
                marginBottom={headerMargin}
                textAlign={'center'}
            >
                <StaticImage
                    alt={'header'}
                    src={'../images/impressies/Blok-2-en-3.jpg'}
                    aspectRatio={1370/405}
                />
            </Box>
            <Container>
                <Blok1En5 />
                <Blok2En3 />
                <Blok4En6 />
            </Container>
            <Gevelaanzichten />
            <Box
                paddingY={sectionPadding}
            >
                <Container>
                    <Prijzen />
                </Container>
            </Box>
        </article>
    );
}

export const query = graphql`
    fragment BouwtypeBeschikbaar on Bouwtype {
        hoofdfoto {
            localFile {
                childImageSharp {
                    gatsbyImageData(
                        placeholder: BLURRED
                        formats: [AUTO, WEBP, AVIF]
                        aspectRatio: 1.1
                        layout: FULL_WIDTH
                    )
                }
            }
        }
    }
`
