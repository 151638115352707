import {graphql, useStaticQuery} from "gatsby";
import * as React from "react";
import {Blok} from "../../pages/beschikbaar";

const Blok2En3 = () => {
    const data = useStaticQuery(graphql`
        query {
            allBouwnummer(filter: {bouwtype: {algemeen: {omschrijving: {in: ["Blok 2", "Blok 3"]}}}}) {
                nodes {
                    ...Bouwnummer
                }
            }
            bouwtype(algemeen: {omschrijving: {eq: "Blok 2"}}) {
                ...BouwtypeBeschikbaar
            }
        }
    `)
    return (
        <Blok bouwnummers={data.allBouwnummer.nodes} omschrijving={'Blok 2 en 3'} impressie={data.bouwtype.hoofdfoto} />
    )
}

export default Blok2En3